.component {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 20px;
  width: auto;
  height: auto;
}
.analysisTarget {
  width: 510px;
}
.dx-toolbar .dx-toolbar-items-container {
  height: 50px;
}
.dx-item .dx-box-item {
  height: 100%;
}
.pageContent {
  display: flex;
}
.dx-button-has-text {
  min-width: 40px;
}
.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
  border-radius: 0%;
  background-color: #fff;
}
.title {
  padding: 20px 0 20px 0;
  font-size: 18px;
  font-weight: 500;
}
// .resizer {
//   border: 2px solid;
//   padding: 20px;
//   width: 300px;
//   resize: vertical;
//   overflow: auto;
// // }
// #accordion h1 {
//   font-size: 20px;
// }

// #accordion h1,
// #accordion p {
//   margin: 0;
// }

// .dx-theme-material #accordion .dx-accordion-item-opened .dx-accordion-item-title {
//   display: flex;
// }

// .dx-theme-material #accordion .dx-accordion-item-opened h1 {
//   align-self: center;
// }

// .options,
// .selected-data {
//   padding: 20px;
//   background-color: rgba(191, 191, 191, 0.15);
//   margin-top: 20px;
// }

// .selected-data {
//   position: relative;
//   height: 36px;
// }

// .selected-data > .caption {
//   position: relative;
//   top: 5px;
//   margin-right: 10px;
//   font-weight: bold;
//   font-size: 115%;
// }

// .selected-data > .dx-widget {
//   position: absolute;
//   left: 140px;
//   right: 20px;
//   top: 20px;
// }

// .options > .caption {
//   font-weight: 500;
//   font-size: 18px;
// }

// .option {
//   margin-top: 10px;
// }
// .layoutBtn {
//   border-radius: 0%;
//   height: -webkit-fill-available;
// }
