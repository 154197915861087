// .widget-container {
//   margin-right: 320px;
//   padding: 20px;
//   max-width: 550px;
//   min-width: 300px;
// }
#form {
  margin-top: 25px;
}
.options {
  padding: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 260px;
  top: 0;
  background-color: rgba(191, 191, 191, 0.15);
}
.caption {
  font-size: 18px;
  font-weight: 500;
}
.option {
  margin-top: 10px;
}
.dx-button.dx-button-success {
  width: 100%;
}
.dateBoxGroup {
  display: flex;
}
.dateBox {
  padding-left: 20px;
  padding-right: 20px;
}
.itemBox {
  padding-left: 20px;
  padding-right: 20px;
}
.asdf {
  border: double;
  width: 100%;
  height: 100%;
}
.qwer {
  display: inline-flex;
  height: 100%;
  width: 100%;
}
