.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}
input {
  font-family: arial;
  font-size: 16px;
  color: #34495e;
  text-decoration: none;
  line-height: 50px;
  transition: all 0.5s ease;
  z-index: 2;
  position: relative;
}
button {
  font-family: arial;
  font-size: 16px;
  color: #34495e;
  text-decoration: none;
  transition: all 0.5s ease;
  z-index: 2;
  position: relative;
}
.mar10 {
  margin-right: 10px;
}
